import React from "react";
import { createRoot } from "react-dom/client";
import { WrappedIntlProvider } from "./react-components/wrapped-intl-provider";
import registerTelemetry from "./telemetry";
import "./utils/theme";
import { HomePage } from "./react-components/home/HomePage";
import { AuthContextProvider } from "./react-components/auth/AuthContext";
import "./react-components/styles/global.scss";
import { ThemeProvider } from "./react-components/styles/theme";
import { store } from "./utils/store-instance";
import { Auth0Provider } from "@auth0/auth0-react";

registerTelemetry("/home", "Hubs Home Page");

window.APP = { store };

function HomeRoot() {
  return (
    <WrappedIntlProvider>
      <ThemeProvider store={store}>
        <Auth0Provider
          domain="dev-16jq37lrd42txvpb.eu.auth0.com"
          clientId="WiRANdgf56P91ymnbbGTwuEdkd46O3YS"
          authorizationParams={{
            redirect_uri: window.location.origin
          }}
        >
          <AuthContextProvider store={store}>
            <HomePage />
          </AuthContextProvider>
        </Auth0Provider>
      </ThemeProvider>
    </WrappedIntlProvider>
  );
}

const container = document.getElementById("home-root");
const root = createRoot(container);
root.render(<HomeRoot />);
