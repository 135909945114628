/* eslint-disable react/prop-types */
/* eslint-disable @calm/react-intl/missing-formatted-message */
import React, { useEffect, useState } from "react";
import { getReticulumFetchUrl } from "../../utils/phoenix-utils";

export const UserRooms = ({ accountId }) => {
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserRooms = async () => {
      try {
        setLoading(true);
        const url = getReticulumFetchUrl(`/api/v1/rooms/user/${accountId}`);
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.APP.store.state.credentials.token}` // Include the user's token
          }
        });
        const result = await response.json();

        const uniqueRooms = result.filter((room, index, self) => index === self.findIndex(r => r.id === room.id));

        setRooms(uniqueRooms);
      } catch (err) {
        console.error("Error fetching rooms:", err);
        //setError("Failed to fetch rooms.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserRooms();
  }, [accountId]);

  if (loading) return <div>Loading rooms...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h2>Accessible Rooms</h2>
      {rooms?.length === 0 ? (
        <p>No rooms available at this moment.</p>
      ) : (
        <ul>
          {rooms?.map(room => (
            <li key={room.id}>
              <a
                href={`https://${window.location.hostname}${window.location.hostname === "hubs.local" ? ":8080" : ""}/${
                  room.hub_sid
                }/${room.slug}`}
              >
                {room.name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
